<!--
 * @Author: Libra
 * @Date: 2021-06-08 16:48:00
 * @LastEditTime: 2024-05-21 10:37:23
 * @LastEditors: Libra
 * @Description: 所有监控的顶层组件 腾讯版本 人脸识别前端来做
 * @FilePath: /stone-exam-ui/src/views/components/DetectLayout.vue
-->
<template>
  <div class="detect-layout">
    <audio ref="audios" src="../../assets/audio/notice.mp3"></audio>
    <video
      id="video3"
      muted="muted"
      width="640px"
      height="480px"
      v-show="false"
    ></video>
    <canvas id="camera3" v-show="false" width="640" height="480"></canvas>
    <div class="camera-monitor"></div>
    <div class="screen-monitor"></div>
    <GlobalDialog
      :dialogVisible="showScreenShare"
      title="录屏共享桌面提示"
      width="600px"
      @dialog-cancel="screenRecord"
      :show_close="false"
      btn_title="开始共享屏幕"
    >
      <div class="camera-snap-container">
        <div class="content screen-share">
          <span
class="title"
            >* 本场考试要求录制您的电脑，请根据浏览器提示共享您的电脑,
            关闭录屏将会影响您的成绩。</span
          >
          <img
            src="@/assets/images/screenShare.jpeg"
            alt=""
            style="width: 500px; height: auto; top: 0; transform: translate(0)"
          />
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showCameraSnap"
      @dialog-cancel="showCameraSnap = false"
      :show_close="false"
      title="提示"
      width="500px"
      btn_title="我知道了"
    >
      <div class="camera-snap-container">
        <div class="content">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span
class="title"
              >即将启动摄像头监拍：<span
                style="color: #cb2a1d; font-size: 24px;"
                >{{ time }} </span
              >秒</span
            >
            <span class="detail">请正面注视摄像头～～</span>
            <span class="detail">如戴口罩，请立刻摘下口罩！</span>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showFaceNotPass"
      @dialog-cancel="showFaceNotPass = false"
      title="提示"
      :show_close="false"
      width="500px"
    >
      <div class="camera-snap-container">
        <div class="content">
          <div class="icon">
            <i class="iconfont iconrenlianshibie"></i>
            <i class="iconfont iconguanbi-3"></i>
          </div>
          <div class="right">
            <span
class="title"
              >身份验证结果：<span
style="color: #cb2a1d; font-size: 28px;"
                >未通过</span
              ></span
            >
            <span class="name">姓名：{{ $store.state.userInfo.realName }}</span>
            <span
class="identity"
              >身份证号：{{ $store.state.userInfo.idCardNum }}</span
            >
            <span
class="detail"
style="width:240px;"
              >如果由于低头没有拍到正脸，或者光线暗导致不通过，监考官会进行人工核验。
              下次拍照时，请抬头看摄像头！</span
            >
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showFacePass"
      @dialog-cancel="showFacePass = false"
      title="提示"
      :show_close="false"
      width="500px"
    >
      <div class="camera-snap-container">
        <div class="content">
          <div class="icon">
            <i class="iconfont iconrenlianshibie"></i>
            <i class="iconfont iconduigoutianchong-"></i>
          </div>
          <div class="right">
            <span
class="title"
              >身份验证结果：<span
style="color: #7ca2ec; font-size: 28px;"
                >通过</span
              ></span
            >
            <span
class="detail"
              >注意：答题全程，请确保脸部在摄像头<br />
              监拍范围，清晰可见!</span
            >
          </div>
        </div>
      </div>
    </GlobalDialog>
  </div>
</template>
<script>
import { dataURLtoFile } from '@/utils/common.js'
import GlobalDialog from '@/components/GlobalDialog'
import Api from '@/api/api'
import TRTC from 'trtc-js-sdk'
import { bus } from '@/utils/bus'
import * as faceapi from 'face-api.js'
import { file_host } from '@/api/config'
import Worker from '@/worker/faceDetection.worker.js'
import { getItem, setItem } from '@/utils/storage'
import { MessageBox } from 'element-ui'

export default {
  data() {
    return {
      token: null,
      ossToken: null,
      count: null,
      showScreenShare: false,
      // 屏幕录制
      screenShotVideoType: this.$store.state.examInfo.screenShotVideoType || 0,
      // 屏幕监拍,用于ai监考
      aiScreenShotVideoType:
        this.$store.state.examInfo.aiScreenShotVideoType || 0,
      // 摄像头录制
      cameraVideoType: this.$store.state.examInfo.cameraVideoType || 0,
      // 摄像头监拍,用于ai监考
      aiCameraVideoType: this.$store.state.examInfo.aiCameraVideoType || 0,
      cameraMonitorType: this.$store.state.examInfo.cameraMonitorType || 0,
      showCameraSnap: false,
      showFaceNotPass: false,
      showFacePass: false,
      time: 5,
      values: [],
      avatar: null,
      faceResultTimer: null,
      snapCount: 0,
      client: null,
      screenClient: null,
      options: {
        roomNumber: 0,
        sig: '',
        userId: '',
        appId: ''
      },
      screenOptions: {
        roomNumber: 0,
        sig: '',
        userId: '',
        appId: ''
      },
      cameraLocalStream: null,
      screenLocalStream: null,
      isPractice: this.$route.query.isPractice || false,
      // AI 相关
      videoEl: null,
      canvasEl: null,
      nets: 'ssdMobilenetv1',
      Modeloptions: null, // 模型参数
      withBoxes: true, // 框or轮廓
      detectFace: 'detectAllFaces', // 单or多人脸
      detection: 'landmark',
      context: null,
      faceNum: 0,
      distance: null,
      pitchAngle: null,
      state: null,
      photoImg: null,
      oriImg: null,
      aiOptions: null,
      fnCanvas: null,
      imgCanvas: null,
      landmarkCanvas: null,
      oriImageData: null,
      /**
       *  AI_NO_FACE(400, "AI 未检测到人脸"),
          AI_MORE_FACE(401, "AI 检测到多张人脸"),
          AI_WRONG_FACE(402, "AI 人脸对比失败")
       */
      preDetectFailed: 0,
      faceDetectionWorker: null,
      consecutiveCount: 0,
      messageCount: 0
    }
  },
  components: {
    GlobalDialog
  },
  beforeDestroy() {
    this.faceDetectionWorker && this.faceDetectionWorker.terminate()
  },
  watch: {
    $route(to) {
      if (to.name === 'Complete') {
        this.stopRecord()
        this.stopScreenRecord()
      }
    }
  },
  async mounted() {
    TRTC.Logger.setLogLevel(process.env.NODE_ENV === 'development' ? TRTC.Logger.LogLevel.NONE : TRTC.Logger.LogLevel.INFO)
    this.cameraFiveMinuteSnap()
    this.initCamera()
    this.faceRecognization()
    if (this.isPractice) return
    await this.cameraRecord()
    if (this.screenShotVideoType === 0 || this.$store.state.pcDetect) return
    this.showScreenShare = true
    bus.$on('screenShare', () => {
      this.screenRecord()
    })
  },
  methods: {
    // check value
    checkValue(value) {
      this.values.push(value)
      if (this.values.length > 10) {
        this.values.shift()
      }
      if (this.values.length === 10 && this.values.every(v => v >= 5)) {
        this.values = []
        let str = '监测到您当前网络连接状况不佳，建议更换为更稳定的网络环境，或者使用手机热点，进行作答。'
        const companyUuid = this.$store.state.companyUuid
        const devCompanyUuidForBeike = '624d2eb0bbe582058a7bfb9a'
        const proCompanyUuidForBeike = '627a157891f8be345300ba36'
        const isPro = process.env.NODE_ENV === 'production'

        if (isPro && companyUuid === proCompanyUuidForBeike || !isPro && companyUuid === devCompanyUuidForBeike) {
          str = '您当前网络不佳，为确保考试顺畅，请把手机断开Wi-Fi，使用个人流量进行监控!'
        }
        const netError = JSON.parse(getItem('showNetError') || '[]')
        if (netError.length === 0) {
          setItem('showNetError', JSON.stringify([1, false]))
          this.$message.error({ message: str, duration: 8000 })
        } else {
          if (netError[1]) return
          if (netError[0] === 3) {
            MessageBox.confirm(str, '提示', {
              confirmButtonText: '我知道了',
              cancelButtonText: '不再提示',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: 'warning'
            }).then(() => {
              setItem('showNetError', JSON.stringify([1, false]))
            }).catch(() => {
              setItem('showNetError', JSON.stringify([1, true]))
            })
          } else {
            this.$message.error({ message: str, duration: 8000 })
            setItem('showNetError', JSON.stringify([netError[0] + 1, false]))
          }
        }
      }
    },
    // 获取 rtc token
    async getCameraToken() {
      const res = await Api.getCameraSig()
      this.options = res.data
    },
    async getScreenToken() {
      const res = await Api.getScreenSig()
      this.screenOptions = res.data
    },
    async cameraRecord() {
      if (this.cameraVideoType === 0) return
      await this.getCameraToken()
      await this.initClient()
      await this.joinChannel()
      await this.enableAI()
    },
    async screenRecord() {
      this.showScreenShare = false
      await this.getScreenToken()
      await this.initScreenClient()
      await this.joinScreenChannel()
    },
    async enableAI() {
      const isEnableAI = this.$store.state.examInfo.isEnableAI
      if (isEnableAI) {
        this.faceDetectionWorker = new Worker()
        this.initWorkerMessage()
        await this.fnInit()
      }
    },
    // init worker onmessage
    initWorkerMessage() {
      let faceNumZeroIdx = 0
      let faceNumMoreIdx = 0
      this.faceDetectionWorker.onmessage = async e => {
        const { type, payload } = e.data
        const { candidateUuid, examUuid } = this.$store.state.userInfo
        const time = new Date().getTime()
        const path = `ai/${examUuid}/${candidateUuid}_${time}.jpg`
        switch (type) {
          case 'faceLandmarkResult':
            if (payload && !this.videoEl.paused) {
              this.faceNum = payload.length
              if (this.faceNum === 0) {
                if (this.preDetectFailed === 400) {
                  const blob = await this.landmarkCanvas.convertToBlob()
                  const file = new File([blob], path, { type: 'image/jpeg' })
                  await this.snapAI(file)
                  await Api.addAiAction({
                    path,
                    type: 400,
                    text: this.faceNum,
                    value: ''
                  })
                  this.preDetectFailed = 0
                  console.log('未检测到人脸')
                  const showFaceNumZero = JSON.parse(getItem('showFaceNumZero') || 'false')
                  if (showFaceNumZero) return
                  const str = 'AI监测到您头像离开监控画面，请调整电脑摄像头角度，确保面部正面在画面中，不要低头或离开座位。如果误提示，则点“不再提示”即可。'
                  if (faceNumZeroIdx === 2) {
                    MessageBox.confirm(str, '提示', {
                      confirmButtonText: '我知道了',
                      cancelButtonText: '不再提示',
                      closeOnClickModal: false,
                      closeOnPressEscape: false,
                      showClose: false,
                      type: 'warning'
                    }).then(() => {
                      setItem('showFaceNumZero', JSON.stringify(false))
                    }).catch(() => {
                      setItem('showFaceNumZero', JSON.stringify(true))
                    })
                  }
                  this.$message({
                    message: 'AI监考官提醒：未检测到人脸画面，请保持脸部在摄像头范围！',
                    type: 'error',
                    duration: 3000
                  })
                  faceNumZeroIdx++
                } else {
                  this.preDetectFailed = 400
                }
                return
              } else if (this.faceNum > 1) {
                const blob = await this.landmarkCanvas.convertToBlob()
                const file = new File([blob], path, { type: 'image/jpeg' })
                await this.snapAI(file)
                await Api.addAiAction({
                  path,
                  type: 401,
                  text: this.faceNum,
                  value: ''
                })
                this.preDetectFailed = 0
                console.log('检测到多张人脸')
                const showFaceNumMore = JSON.parse(getItem('showFaceNumMore') || 'false')
                if (showFaceNumMore) return
                const str = 'AI监测到您周边有多人出现在监控画面，请确保座位周边无人打扰。如果误提示，则点“不再提示”即可。'
                if (faceNumMoreIdx === 2) {
                  MessageBox.confirm(str, '提示', {
                    confirmButtonText: '我知道了',
                    cancelButtonText: '不再提示',
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    showClose: false,
                    type: 'warning'
                  }).then(() => {
                    setItem('showFaceNumMore', JSON.stringify(false))
                  }).catch(() => {
                    setItem('showFaceNumMore', JSON.stringify(true))
                  })
                }
                this.$message({
                  message: 'AI监考官提示：有多人出现在监控画面中，请遵守考试纪律！',
                  type: 'error',
                  duration: 3000
                })
                faceNumMoreIdx++
                return
              }
              const avatar = this.$store.state.userInfo.avatar
              if (!avatar) {
                console.log('请先上传头像')
                return
              }
              this.start()
            }
            break
          case 'compareFaceDescriptorsResult':
            this.distance = payload
            console.log('distance', this.distance)
            this.checkDistance(this.distance, path)
            break
          default:
            break
        }
      }
    },
    // 创建频道
    async initClient() {
      const { sig, userId, appId } = this.options
      const option =
        this.cameraVideoType === 1
          ? {
            mode: 'rtc',
            sdkAppId: appId,
            userId,
            userSig: sig,
            autoSubscribe: false
          }
          : {
            mode: 'rtc',
            sdkAppId: appId,
            userId,
            userSig: sig,
            userDefineRecordId: userId,
            autoSubscribe: false
          }
      this.client = await TRTC.createClient(option)
      window.client = this.client
    },
    // 创建屏幕录制频道
    async initScreenClient() {
      const { sig, userId, appId } = this.screenOptions
      const option =
        this.screenShotVideoType === 1
          ? {
            mode: 'rtc',
            sdkAppId: appId,
            userId,
            userSig: sig,
            autoSubscribe: false
          }
          : {
            mode: 'rtc',
            sdkAppId: appId,
            userId,
            userSig: sig,
            userDefineRecordId: userId,
            autoSubscribe: false
          }
      this.screenClient = await TRTC.createClient(option)
      window.screenClient = this.screenClient
    },

    // 加入频道
    async joinChannel() {
      this.$store.commit('setShowCamera', true)
      if (window.cameraLocalStream) {
        this.cameraLocalStream = window.cameraLocalStream
        const dom = document.getElementById('video-right')
        dom && !dom.innerHTML && this.cameraLocalStream.play(dom)
        return
      }
      this.client
        .join({ roomId: this.options.roomNumber })
        .then(async() => {
          const micList = await TRTC.getMicrophones()
          try {
            this.cameraLocalStream = await TRTC.createStream({
              userId: this.options.userId,
              audio: micList.length !== 0,
              video: true,
              facingMode: 'user'
            })
          } catch (error) {
            console.log('err')
          }
          this.cameraLocalStream.setVideoProfile({
            width: 640,
            height: 480,
            frameRate: 30,
            bitrate: 300 /* kpbs */
          })
          await this.client.enableSmallStream()
          this.cameraLocalStream
            .initialize()
            .then(() => {
              window.cameraLocalStream = this.cameraLocalStream
              const dom = document.getElementById('video-right')
              dom && !dom.innerHTML && this.cameraLocalStream.play(dom)
              this.client
                .publish(this.cameraLocalStream)
                .then(async() => {
                  this.$store.commit('setCameraDetect', true)
                  console.log('本地流发布成功')
                })
                .catch(error => {
                  this.$store.commit('setCameraDetect', false)
                  console.error('本地流发布失败 ' + error)
                })
              console.log('初始化本地流成功')
            })
            .catch(error => {
              if (String(error).includes('NotAllowedError')) {
                this.$message.error('摄像头被禁用，请检查权限！')
                console.error('摄像头被禁用，请检查权限！')
                return
              }
              this.$message.error('初始化本地流失败')
              console.error('初始化本地流失败 ' + error)
            })
        })
        .catch(error => {
          console.error('进房失败 ' + error)
        })
      if (this.screenShotVideoType === 0) {
        this.client.on('network-quality', async(event) => {
          const { uplinkNetworkQuality } = event
          const uplinkQuality = uplinkNetworkQuality
          this.checkValue(uplinkQuality)
        })
      }
    },
    // util function
    // 输入值 distance 连续三次大于 0.55 时，message 提示
    // 如果连续三次 message 提示后，modal 提示
    async checkDistance(distance, path) {
      const needTip = !(JSON.parse(getItem('showFaceDiffErr') || 'false'))
      if (distance > 0.55) {
        this.consecutiveCount++
        if (this.consecutiveCount >= 3) {
          const blob = await this.fnCanvas.convertToBlob()
          const file = new File([blob], path, { type: 'image/jpeg' })
          await this.snapAI(file)
          await Api.addAiAction({
            path,
            type: 402,
            text: '',
            value: this.distance.toFixed(4)
          })
          this.messageCount++
          needTip && this.$message({
            message: 'AI人脸对比失败，已记录失败数据，确保您的面部清晰可见！',
            type: 'error',
            duration: 3000
          })
          this.consecutiveCount = 0
        }
      } else {
        this.consecutiveCount = 0
        this.messageCount = 0
      }
      if (this.messageCount >= 3) {
        const str = 'AI监测到您头像比对失败，请调整电脑摄像头角度，确保面部正面在画面中，不要低头。如果误提示，则点“不再提示”即可。'
        needTip && MessageBox.confirm(str, '提示', {
          confirmButtonText: '我知道了',
          cancelButtonText: '不再提示',
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showClose: false,
          type: 'warning'
        }).then(() => {
          setItem('showFaceDiffErr', JSON.stringify(false))
        }).catch(() => {
          setItem('showFaceDiffErr', JSON.stringify(true))
        })
        this.messageCount = 0
      }
    },

    // 加入屏幕录制频道
    async joinScreenChannel() {
      this.$store.commit('setShowPc', true)
      this.screenLocalStream = TRTC.createStream({
        userId: this.screenOptions.userId,
        audio: false,
        screen: true
      })
      this.screenLocalStream.setScreenProfile({
        width: 1280,
        height: 720
      })
      await this.screenClient.enableSmallStream()
      try {
        await this.screenLocalStream.initialize()
        window.screenLocalStream = this.screenLocalStream
        const { displaySurface } = this.screenLocalStream.getVideoTrack().getSettings()
        if (displaySurface !== 'monitor') {
          this.$message.error('请选择整个屏幕进行分享，而不是某个窗口或者标签页')
          // 屏幕分享客户端取消发布流
          await this.screenClient.unpublish(this.screenLocalStream)
          // 关闭屏幕分享流
          this.screenLocalStream.close()
          // 屏幕分享客户端退房
          await this.screenClient.leave()
          this.joinScreenChannel()
          return
        }
      } catch (e) {
        // 当屏幕分享流初始化失败时, 提醒用户并停止后续进房发布流程
        switch (e.name) {
          case 'NotReadableError':
            // 提醒用户确保系统允许当前浏览器获取屏幕内容
            return
          case 'NotAllowedError':
            if (e.message === 'Permission denied by system') {
              // 提醒用户确保系统允许当前浏览器获取屏幕内容
            } else {
              // 用户拒绝/取消屏幕分享
            }
            return
          default:
            // 初始化屏幕分享流时遇到了未知错误，提醒用户重试
            return
        }
      }
      this.screenClient
        .join({ roomId: this.screenOptions.roomNumber })
        .then(() => {
          console.log('进房成功')
          this.screenClient
            .publish(this.screenLocalStream)
            .then(() => {
              this.$store.commit('setPcDetect', true)
              console.log('本地流发布成功')
            })
            .catch(error => {
              this.$store.commit('setPcDetect', false)
              console.error('本地流发布失败 ' + error)
            })
        })
        .catch(error => {
          console.error('进房失败 ' + error)
        })
      this.screenClient.on('network-quality', async(event) => {
        const { uplinkNetworkQuality } = event
        const uplinkQuality = uplinkNetworkQuality
        this.checkValue(uplinkQuality)
      })
      // 屏幕分享流监听屏幕分享停止事件
      this.screenLocalStream.on('screen-sharing-stopped', async event => {
        // 屏幕分享客户端停止推流
        await this.screenClient.unpublish(this.screenLocalStream)
        // 关闭屏幕分享流
        this.screenLocalStream.close()
        // 屏幕分享客户端退房
        await this.screenClient.leave()
        this.$store.commit('setPcDetect', false)
      })
    },

    // 离开频道
    async stopRecord() {
      this.client
        .leave()
        .then(() => {
          console.log('退出房间成功')
        })
        .catch(error => {
          console.error('退房失败 ' + error)
        })
      this.client = null
    },
    // 离开频道
    async stopScreenRecord() {
      // 屏幕分享客户端停止推流
      await this.screenClient.unpublish(this.screenLocalStream)
      // 关闭屏幕分享流
      this.screenLocalStream.close()
      // 屏幕分享客户端退房
      await this.screenClient.leave()
    },
    async initCamera() {
      const video = document.getElementById('video3')
      window.cameraStream = await navigator.mediaDevices.getUserMedia({
        video: { width: 640, height: 480 },
        audio: false
      })
      video.srcObject = window.cameraStream
      video.play()
    },
    // 抓拍
    async snap(name) {
      const canvas = document.getElementById('camera3')
      const video = document.getElementById('video3')
      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      const base64 = canvas.toDataURL('image/jpg')
      return dataURLtoFile(base64, name)
    },
    // 拍照上传
    async snapAndUpload() {
      if (this.isPractice) return
      if (this.snapCount === 0) {
        await this.getMonitorToken()
      }
      this.snapCount++
      if (this.snapCount === 3) {
        this.snapCount = 0
      }
      const { candidateUuid, examUuid } = this.$store.state.userInfo
      const time = new Date().getTime()
      const path = this.token.monitorCameraPath
      const file = await this.snap(`${path}/${candidateUuid}_${time}.jpg`)
      if (!file) return
      this.ossUploadMonitor(file, file)
      this.updateSlice({
        candidateUuid: candidateUuid,
        examUuid: examUuid,
        name: `${candidateUuid}_${time}.jpg`,
        path: `${path}/${candidateUuid}_${time}.jpg`,
        type: 1
      })
    },
    // camera 抓拍 5分钟一次，用于展示
    cameraFiveMinuteSnap: async function() {
      if (this.cameraMonitorType === 0) return
      const fiveMinute = 5 * 60 * 1000
      const t = setInterval(async() => {
        await this.snapAndUpload()
      }, fiveMinute)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    // 判断是否有摄像头
    async hasCamera() {
      const devices = await navigator.mediaDevices.enumerateDevices()
      for (const device of devices) {
        if (device.kind === 'videoinput') {
          return true
        }
      }
      return false
    },
    // 人脸识别对比
    faceRecognization: async function() {
      // 如果考生没有摄像头，则不进行人脸识别
      if (!(await this.hasCamera())) return
      // 开启了五分钟拍照之后，才可以进行考中三次人脸识别（波说的）
      if (this.cameraMonitorType === 0) return
      if (this.isPractice) return
      // 获取考试开始时间
      const res = await Api.differenceTime()
      const resData = res.data
      if (resData.candidateStartedAt === 0) return
      // 获取系统时间
      const time = await Api.getTime()
      let sysTime = time.data.timestamp
      const etime = resData.jobEndAt
      // 取出开始时间
      const stime = resData.candidateStartedAt
      // 将开始时间到考试结束时间的时间段平均分成三份,计算每段时间间隔
      const interval = Math.floor((etime - stime) / 3)
      // 在第一个时间段之间，随机找一个时间点
      const randomTime = resData.faceDifferenceRandom + stime
      console.warn(randomTime, sysTime, interval)
      this.count = resData.faceDifferenceTimes
      console.log('第一次人脸识别剩余', randomTime - sysTime + '秒')
      console.log('第二次人脸识别剩余', randomTime + interval - sysTime + '秒')
      console.log(
        '第三次人脸识别剩余',
        randomTime + 2 * interval - sysTime + '秒'
      )
      const t = setInterval(async() => {
        sysTime += 1
        if (randomTime - sysTime + this.count * interval < 0) return
        // 播放音频提醒考生
        if (randomTime + this.count * interval - sysTime === 7) {
          this.$refs.audios.play()
        }
        if (sysTime + 5 >= randomTime + this.count * interval) {
          this.count++
          this.showCameraSnap = true
          this.countDown()
          console.log(`第${this.count}次对比`)
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    // 上传并更新人脸对比图
    async uploadAndUpdateDiff(file, time, path) {
      const candidateUuid = this.$store.state.userInfo.candidateUuid
      const examUuid = this.$store.state.userInfo.examUuid
      this.ossUpload(file, file)
      this.updateSlice({
        candidateUuid: candidateUuid,
        examUuid: examUuid,
        name: `${candidateUuid}_${time}.jpg`,
        path: `${path}/${candidateUuid}_${time}.jpg`,
        type: 7
      })
    },
    // 更新切片信息
    updateSlice: async function(data) {
      await Api.updateSlice(data)
    },
    // 获取 oss Token
    getMonitorToken: async function() {
      // 每 30 分钟获取一次
      const res = await Api.ossMonitorToken()
      this.token = res.data
      const t = setInterval(async() => {
        const res = await Api.ossMonitorToken()
        this.token = res.data
      }, 1000 * 60 * 30)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    // 获取 Token
    getToken: async function() {
      // 每30 分钟获取一次
      const res = await Api.ossToken()
      this.ossToken = res.data
      const t = setInterval(async() => {
        const res = await Api.ossToken()
        this.ossToken = res.data
      }, 1000 * 60 * 30)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    // 上传阿里云
    ossUpload: async function(blob, file) {
      const data = {
        filename: file.name,
        blob: blob,
        ossInfo: this.ossToken
      }
      await Api.ossUploadFile(data)
    },
    // 上传阿里云
    ossUploadMonitor: async function(blob, file) {
      const data = {
        filename: file.name,
        blob: blob,
        ossInfo: this.token
      }
      await Api.ossUploadFile(data)
    },
    async countDown() {
      await this.getToken()
      const candidateUuid = this.$store.state.userInfo.candidateUuid
      let t = setInterval(async() => {
        this.time--
        if (this.time === 0) {
          this.showCameraSnap = false
          const time = new Date().getTime()
          const path = this.ossToken.avatarPath
          const name = `${path}/${candidateUuid}_${time}.jpg`
          const file = await this.snap(name)
          clearInterval(t)
          await this.uploadAndUpdateDiff(file, time, path)
          // 人脸对比
          t = setTimeout(async() => {
            const res = await Api.difference({
              path: name
            })
            clearTimeout(t)
            if (res.code === 30077) return
            // 10秒钟后获取人脸对比结果
            setTimeout(this.getFaceDiffResult(t), 10000)
          }, 5000)
          // 拍照上传
          this.time = 5
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    // 获取考试过程中人脸对比结果
    async getFaceDiffResult(t) {
      console.log('正在获取人脸识别结果。。。')
      clearTimeout(t)
      t = null
      const res = await Api.getFaceIdResult()
      if (res.code === 0) {
        if (res.data.faceDifferentRemainingCount === 3 - this.count) {
          clearInterval(this.faceResultTimer)
          this.faceResultTimer = null
          if (res.data.faceDifferenceResult === 1) {
            this.showFacePass = true
            setTimeout(() => {
              this.showFacePass = false
            }, 5000)
          } else if (res.data.faceDifferenceResult === 2) {
            this.showFaceNotPass = true
            setTimeout(() => {
              this.showFaceNotPass = false
            }, 5000)
          }
        } else {
          clearInterval(this.faceResultTimer)
          this.faceResultTimer = null
          this.faceResultTimer = setInterval(async() => {
            await this.getFaceDiffResult()
          }, 5000)
          this.$once('hook:beforeDestroy', () => {
            clearInterval(this.faceResultTimer)
          })
        }
      }
    },
    // 初始化模型加载
    async fnInit() {
      await faceapi.nets[this.nets].loadFromUri('/models') // 算法模型
      await faceapi.loadFaceLandmarkModel('/models') // 轮廓模型
      await faceapi.loadFaceExpressionModel('/models') // 表情模型
      await faceapi.loadAgeGenderModel('/models') // 年龄模型
      await faceapi.loadFaceRecognitionModel('/models')
      this.aiOptions = new faceapi.SsdMobilenetv1Options({
        minConfidence: 0.5 // 0.1 ~ 0.9
      })
      // 节点属性化
      this.fnCanvas = new OffscreenCanvas(640, 480)
      this.imgCanvas = new OffscreenCanvas(640, 480)
      this.landmarkCanvas = new OffscreenCanvas(640, 480)
      this.videoEl = document.querySelector('#video3')
      this.photoImg = this.photoImg || document.createElement('img')
      const timer = setInterval(() => {
        this.fnRunFaceLandmark()
      }, 10000)
      // clearInterval
      this.$once('hook:beforeDestroy', () => {
        console.log('clearInterval AI')
        clearInterval(timer)
      })
    },
    async fnRunFaceLandmark() {
      if (!this.videoEl) return
      if (this.videoEl.paused) return
      const ctx = this.landmarkCanvas.getContext('2d')
      ctx.drawImage(this.videoEl, 0, 0, 640, 480)
      const imageData = ctx.getImageData(0, 0, 640, 480)
      this.faceDetectionWorker.postMessage({
        type: 'runFaceLandmark',
        payload: {
          w: 640,
          h: 480,
          buffer: imageData.data.buffer,
          detectFace: this.detectFace,
          aiOptions: this.aiOptions
        }
      }, [imageData.data.buffer])
    },
    async snapAI(file) {
      await this.getMonitorToken()
      this.ossUploadMonitor(file, file)
    },

    async takePhoto() {
      const canvas = document.getElementById('camera3')
      const video = this.videoEl
      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      const imgUrl = canvas.toDataURL()
      this.photoImg.src = imgUrl
      this.photoImg.style.width = '320px'
      this.photoImg.style.height = '240px'

      return this.photoImg
    },
    loadImageData(url) {
      return new Promise((resolve, reject) => {
        // Step 1: Load the image
        const img = new Image()
        img.crossOrigin = 'Anonymous' // Set the crossorigin attribute if needed
        img.src = url

        img.onload = () => {
          // Step 2: Create a canvas element and get the 2D context
          const ctx = this.imgCanvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          // Step 4: Get the imageData from the context
          const imageData = ctx.getImageData(0, 0, this.imgCanvas.width, this.imgCanvas.height)

          // Resolve the promise with imageData
          resolve(imageData)
        }

        img.onerror = (err) => {
          reject(err)
        }
      })
    },
    async start() {
      const ctx = this.fnCanvas.getContext('2d')
      ctx.drawImage(this.videoEl, 0, 0, 640, 480)
      const imageData = ctx.getImageData(0, 0, 640, 480)
      this.oriImageData = this.oriImageData || await this.loadImageData(`${file_host}${this.$store.state.userInfo.avatar}`)
      this.faceDetectionWorker.postMessage({
        type: 'compareFaceDescriptors',
        payload: {
          w: 640,
          h: 480,
          buffer1: imageData.data.buffer,
          buffer2: this.oriImageData.data.buffer
        }
      }, [imageData.data.buffer])
    }
  }
}
</script>

<style lang="scss" scoped>
.camera-snap-container {
  .screen-share {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    .title {
      color: #cb2a1d;
      font-size: 16px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 100px;
      color: #cb2a1d;
      margin-right: 20px;
    }
    .icon {
      position: relative;
      .iconrenlianshibie {
        color: #ababab;
      }
      .iconguanbi-3 {
        font-size: 28px;
        position: absolute;
        bottom: 12px;
        right: -8px;
        background: #fff;
      }
      .iconduigoutianchong- {
        font-size: 28px;
        position: absolute;
        bottom: 12px;
        right: -8px;
        color: #7ca2ec;
        background: #fff;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .identity {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
