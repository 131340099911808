var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detect-layout" },
    [
      _c("audio", {
        ref: "audios",
        attrs: { src: require("../../assets/audio/notice.mp3") },
      }),
      _c("video", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: {
          id: "video3",
          muted: "muted",
          width: "640px",
          height: "480px",
        },
        domProps: { muted: true },
      }),
      _c("canvas", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { id: "camera3", width: "640", height: "480" },
      }),
      _c("div", { staticClass: "camera-monitor" }),
      _c("div", { staticClass: "screen-monitor" }),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showScreenShare,
            title: "录屏共享桌面提示",
            width: "600px",
            show_close: false,
            btn_title: "开始共享屏幕",
          },
          on: { "dialog-cancel": _vm.screenRecord },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content screen-share" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  "* 本场考试要求录制您的电脑，请根据浏览器提示共享您的电脑, 关闭录屏将会影响您的成绩。"
                ),
              ]),
              _c("img", {
                staticStyle: {
                  width: "500px",
                  height: "auto",
                  top: "0",
                  transform: "translate(0)",
                },
                attrs: {
                  src: require("@/assets/images/screenShare.jpeg"),
                  alt: "",
                },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showCameraSnap,
            show_close: false,
            title: "提示",
            width: "500px",
            btn_title: "我知道了",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showCameraSnap = false
            },
          },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("即将启动摄像头监拍："),
                  _c(
                    "span",
                    { staticStyle: { color: "#cb2a1d", "font-size": "24px" } },
                    [_vm._v(_vm._s(_vm.time) + " ")]
                  ),
                  _vm._v("秒"),
                ]),
                _c("span", { staticClass: "detail" }, [
                  _vm._v("请正面注视摄像头～～"),
                ]),
                _c("span", { staticClass: "detail" }, [
                  _vm._v("如戴口罩，请立刻摘下口罩！"),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showFaceNotPass,
            title: "提示",
            show_close: false,
            width: "500px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showFaceNotPass = false
            },
          },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "icon" }, [
                _c("i", { staticClass: "iconfont iconrenlianshibie" }),
                _c("i", { staticClass: "iconfont iconguanbi-3" }),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("身份验证结果："),
                  _c(
                    "span",
                    { staticStyle: { color: "#cb2a1d", "font-size": "28px" } },
                    [_vm._v("未通过")]
                  ),
                ]),
                _c("span", { staticClass: "name" }, [
                  _vm._v("姓名：" + _vm._s(_vm.$store.state.userInfo.realName)),
                ]),
                _c("span", { staticClass: "identity" }, [
                  _vm._v(
                    "身份证号：" + _vm._s(_vm.$store.state.userInfo.idCardNum)
                  ),
                ]),
                _c(
                  "span",
                  { staticClass: "detail", staticStyle: { width: "240px" } },
                  [
                    _vm._v(
                      "如果由于低头没有拍到正脸，或者光线暗导致不通过，监考官会进行人工核验。 下次拍照时，请抬头看摄像头！"
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showFacePass,
            title: "提示",
            show_close: false,
            width: "500px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showFacePass = false
            },
          },
        },
        [
          _c("div", { staticClass: "camera-snap-container" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "icon" }, [
                _c("i", { staticClass: "iconfont iconrenlianshibie" }),
                _c("i", { staticClass: "iconfont iconduigoutianchong-" }),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("身份验证结果："),
                  _c(
                    "span",
                    { staticStyle: { color: "#7ca2ec", "font-size": "28px" } },
                    [_vm._v("通过")]
                  ),
                ]),
                _c("span", { staticClass: "detail" }, [
                  _vm._v("注意：答题全程，请确保脸部在摄像头"),
                  _c("br"),
                  _vm._v(" 监拍范围，清晰可见!"),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }